import React, { useContext } from 'react'
// import { useForm, ValidationError } from '@formspree/core';
import LanguageContext from '../../contexts/LanguageContext'
import './ContactForm.css'


const ContactForm = ({language}) => { 
const {form,textarea,btn} = language.contact
const context = useContext(LanguageContext)


return(

<form className={`contact-form`} name="contact-us" action="https://formsubmit.co/lonak.secret@gmail.com" method="POST">
<input type="hidden" name="form-name" value="contact-us" />
    {form.map((item,i) => (
        <React.Fragment key={i}>
        <label>{item.value}</label>
        <input type={item.type} className="full-name" name={item.name} />
        </React.Fragment>
    ))}
    <label>{textarea.name}</label>
    <textarea name="subject" id="message" cols="30" rows="6" 
    placeholder={textarea.placeholder}></textarea>
    <input type="submit" value={btn.name} className={`cwhite ${context.language}`} />
</form>


)
}

export default ContactForm