import { BsQuestionDiamondFill } from 'react-icons/bs'
import SectionHeading from '../SectionHeading/SectionHeading'
import './Tipolim.css'
import TipolimCard from '../TipolimCard/TipolimCard'


const Tipolim = ({ language }) => {
    const {p,h2,cards} = language.tipolim;
    return (

        <div className='FAQ flex-center section'>
            <BsQuestionDiamondFill size={30} color='DD5154' />
            <SectionHeading p={p} h2={h2} />
            <divz className="all-Tipolim">
                {cards.map((card, i) => (
                    <TipolimCard key={i} title={card.title}
                        desc={card.desc} />
                ))}
            </divz>

        </div>

    )
}

export default Tipolim