import logo from '../../assets/logo-footer.svg'
import './Footer.css'
import { useContext } from 'react';
import ThemeContext from '../../contexts/ThemeContext';
import { GrInstagram, GrMail } from 'react-icons/gr';
import { BsFillTelephoneFill } from 'react-icons/bs';


const Footer = ({ language }) => {

    const { menu } = language;
    const { theme } = useContext(ThemeContext);

    return (

        <div className='Footer cwhite' style={{ backgroundColor: theme.mode === 'light' ? '#323b43' : theme.bgCards }}>
            <div className="footer-col1 col">
                <img src={logo} alt="QB Media" style={{ width: '100px' }} />
            </div>
            <div className="footer-col4 col">
                <div className="span" style={{ direction: 'rtl' }}>
                    סוכר קליניק קיימת כבר יותר משנה של הצלחה טובה עם הטכנולוגיה המתקדמת ביותר בשוק
                    בכל שירות נמצאת עובדת בקיעה ומיומנת במקצוע שלה, ניקה מתמחה בהסרת שיער וטיפולי אסתטיקה על ידי רופא מוסמך ועוד הרבה טיפולי יופי וטיפוח.
                </div>
            </div>
            <div className="footer-col2 col">

                <div className="footer-links">
                    <ul>
                        {menu.map((item, i) => (
                            <li key={i}><a href={`#${item[1]}`} style={{ color: theme.secondary }}>{item[0]}</a></li>
                        ))}
                    </ul>
                </div>

                <div>
                    <a href="https://instagram.com/sukar.clinic?igshid=MzRlODBiNWFlZA=="><GrInstagram size={30} /></a>
                    <a href="tel:0527431855"><BsFillTelephoneFill size={30} /></a>
                    <a href="mailto:sukarclinic@gmail.com"><GrMail size={30} /></a>
                </div>
                <p>חיפה , רחוב משהו 7</p>
            </div>

            <div className="footer-bottom" style={{ backgroundColor: theme.mode === 'light' ? '#252B31' : "#171717" }}>© 2023 <a href='https://qbmedia.co.il'> קיובי מדיה</a> האתר פותח ע״י</div>
        </div>

    )
}

export default Footer