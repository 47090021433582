import './Navbar.css'
import React, { useContext, useState } from 'react';
import { RiMenuLine, RiCloseLine } from 'react-icons/ri'
import { FaArrowAltCircleUp } from 'react-icons/fa'
import { NavItems } from './NavItems'
import { useEffect } from 'react/';
import { useNavigate } from 'react-router-dom'
import ThemeContext from '../../contexts/ThemeContext';
// import ThemeSwitcher from '../ThemeSwitcher/ThemeSwitcher';

const Navbar = ({ language }) => {

    const { menu } = language;
    let [toggleMenu, setToggleMenu] = useState(false);
    let [toggleScrUp, setToggleScrUp] = useState(false);
    const { theme } = useContext(ThemeContext);
    let y = window.scrollY;
    const navigate = useNavigate();
    const logo = theme.mode === 'light' ? 'logo.svg' : 'logo-dark.webp'

    useEffect(() => {
        window.addEventListener("scroll", (e) => {
            setToggleMenu(false)
            window.scrollY > 800 ? setToggleScrUp(true) : setToggleScrUp(false)
        });
    }, [y])

    return (

        <nav className='Navbar' style={{ backgroundColor: theme.cardsBg }}>
            {/* Navbar Top Left Icon */}
            {/* <ThemeSwitcher /> */}
            {/* <h2 className='nav-third-icon'><RiWhatsappFill color={theme.secondary}/></h2> */}

            {/* Scroll Up Button */}
            {toggleScrUp && <a className="scroll-up" href={`#${menu[0][1]}`}><FaArrowAltCircleUp size={40} /></a>}

            {/* The Logo */}
            <div className='logo-wrapper' onClick={() => navigate('/')}><img src={logo} alt="QB Media" className="logo"
                data-aos="fade-right" data-aos-duration="1500" /></div>

            {/* Hamburger Menu */}
            <div className="links">
                <NavItems menu={menu} />
            </div>

            <div className="mobile-nav">
                {!toggleMenu
                    ? <RiMenuLine size={30} color='F5F0E6' onClick={() => setToggleMenu(true)}
                        data-aos="zoom-in" data-aos-duration="1500" />
                    : <RiCloseLine size={30} color='F5F0E6' onClick={() => setToggleMenu(false)}
                        data-aos="zoom-in" data-aos-duration="500" />
                }

                <div className={`mobile-links bgc-white ${toggleMenu ? 'fade-in-top' : 'fade-in-bottom'}`}
                style={{backgroundColor:theme.cardsBg}}>
                    <NavItems menu={menu} />
                </div>

            </div>
        </nav>

    )
}

export default Navbar