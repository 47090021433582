import { Hero, Footer, Tools, Whatwedo, Whorwe, Contact, JustImage } from "../../Sections"
import { Navbar, WhatsappBubble, Accessibility, FAQ, Tipolim } from "../../Components"
import bg1 from '../../assets/jimg1.png'
import bg2 from '../../assets/bg-pink.jpeg'

const Home = ({ lng, font }) => {

    return (

        <div className={`Home ${font}`}>
            {/* <BottomCircleMenu/> */}
            <Accessibility />
            <WhatsappBubble />
            <Navbar language={lng} />
            <Hero language={lng} />
            <Whorwe language={lng} />
            <JustImage bg={bg1} ht={250} />
            <Tools language={lng} />
            <Whatwedo language={lng} />
            <Tipolim language={lng}/>
            <JustImage bg={bg2} ht={200} />
            <FAQ/>
            {/* <Feedbacks language={lng} /> */}
            <Contact language={lng} />
            <Footer language={lng} />
        </div>

    )
}

export default Home