import './ToolCard.css'
import { useContext, useState } from 'react'
import LanguageContext from '../../contexts/LanguageContext'
import ThemeContext from '../../contexts/ThemeContext'

const ToolCard = ({ title, desc, icon, dir }) => {

    const { language } = useContext(LanguageContext)
    const { theme } = useContext(ThemeContext);
    const [isHover, setIsHover] = useState(false);

    const handleMouseEnter = () => {
        setIsHover(true);
    };
    const handleMouseLeave = () => {
        setIsHover(false);
    };

    return (

        <div className='ToolCard' style={{ backgroundColor: isHover ? (theme.mode === "dark" ? "black" : theme.primary) : theme.cardsBg, 
            color: isHover ? (theme.mode === "dark" ? "vanil" : theme.cardsBg) : theme.primary }} onMouseLeave={e => handleMouseLeave()} onMouseEnter={e => handleMouseEnter()}
            data-aos={`zoom-in`} data-aos-duration="1500">
            <div className="toolIcon"><img src={icon} width={50} height={50} alt="" /></div>
            <h2 className={`tc-${language}`}>{title}</h2>
            <p className={`tc-${language}`}>{desc}</p>
        </div>

    )
}

export default ToolCard