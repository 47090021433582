import { MdStore } from 'react-icons/md'
import { FaChevronDown } from 'react-icons/fa'
import { SectionHeading } from '../../Components'
import './Whorwe.css'


const Whorwe = ({language}) => { 
const {p,h2,desc} = language.whorwe
return(

<div className='Whorwe flex-center section' id={language.menu[4]} style={{marginTop:"100px"}}>
<MdStore size={30} color='DD5154' />
<SectionHeading p={p} h2={h2} />
<p style={{marginBottom:"15px"}}><span className='biz-name'>סוכר קליניק </span>{desc}</p>
<FaChevronDown size={30} color='DC5254' onClick={() => window.scrollTo(0,900)}/>
</div>

)
}

export default Whorwe