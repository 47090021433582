import {createContext,useState} from 'react';

const PricingContext = createContext();
export const PricingProvider = ({children}) => {
  const [pricingPlan,setPricingPlan] = useState('monthly');
  const [currentPlan, setCurrentPlan] = useState('both');


  // change pricing plan
  const changePricingPlan = (pricingPlan) => {
    if(pricingPlan === 'monthly'){
        setPricingPlan('yearly')
    }else{
        setPricingPlan('monthly')
    }
  }
  

  return <PricingContext.Provider value={{
    pricingPlan,
    currentPlan,
    changePricingPlan,
    setCurrentPlan
    }}>
{children}
    </PricingContext.Provider>
}

export default PricingContext