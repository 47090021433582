import { useEffect, useState,useMemo, useContext } from 'react';
import {wspLink} from '../../utils/WspLink'
import LanguageContext from '../../contexts/LanguageContext'
import h1_img from '../../assets/h1.png';
import h2_img from '../../assets/h2.jpg';

import './Hero.css'


const Hero = ({ language }) => {

    const { h1, p1, h2, p2, Orderbutton,contactButton } = language.hero;
    const images = useMemo(() => {return [h1_img, h2_img ]},[]);
    const titles = [h1, h2];
    const subtitles = [p1, p2];
    const [currentImage, setCurrentImage] = useState(images[0]);
    const context = useContext(LanguageContext)
    const [counter, setCounter] = useState(0);
    
    useEffect(() => {
        
        setCurrentImage(images[counter]);
        const intervalId = setInterval(() => {
            if (counter >= images.length - 1) setCounter(0);
            else setCounter(counter + 1);
        }, 4500)
        return () => clearInterval(intervalId);
    }, [counter,images])

    const onContactClick = () => {
        window.open(wspLink)
    }

    return (
        <>
            <div className='Hero flex-center' id={language.menu[0][1]} style={{ backgroundImage: `url(${currentImage})`, transition:'all 0.6s ease'}}>
                <div className="bg-overlay"></div>
                <h1 data-aos="zoom-in-down" data-aos-duration="2000">{titles[counter]}</h1>
                <p data-aos="fade-up" data-aos-duration="2500">{subtitles[counter]}</p>
                <div className="hero-buttons">
                <button data-aos="fade-up" className={`orderBtn ${context.language}`} data-aos-duration="2500" onClick={e => onContactClick()}>{Orderbutton}</button>
                <a href="#contact" data-aos="fade-up" className={`contactBtn ${context.language}`} data-aos-duration="2500" >{contactButton}</a>
                </div>
            </div>
            {/* <div className="Cards flex-center">
                {cards.map(card => <HeroCard key={card.num} num={card.num} title={card.title}
                    desc={card.desc} color={card.color} />)}
            </div> */}
        </>

    )
}

export default Hero