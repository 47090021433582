import { ContactForm } from '../../Components'
// import { SocialIcons } from '../../Components'
import { AiFillMessage } from "react-icons/ai"
import './Contact.css'


const Contact = ({ language }) => {
    const { p, h2, bg } = language.contact;
    return (

        <div className='Contact flex-center section cwhite' style={{ backgroundImage: `url(${bg})` }} 
        id={language.menu[3][1]}>
            <div className="bg-overlay"></div>
            <AiFillMessage className='sms' size="45" />
            <h2 className='cwhite'>{p}</h2>
            <p className='cwhite'>{h2}</p>
            {/* <SocialIcons language={language} /> */}
            <div className="form-container">
                <ContactForm language={language} />
            </div>
        </div>

    )
}

export default Contact