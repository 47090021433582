import { BsQuestionDiamondFill } from 'react-icons/bs'
import SectionHeading from '../SectionHeading/SectionHeading'
import './FAQ.css'


const FAQ = () => {

    return (

        <div className='FAQ flex-center section'>
            <BsQuestionDiamondFill size={30} color='DD5154' />
            <SectionHeading p={'כדאי לך שתדעי'} h2={'שאלות ותשובות'} />
            <div className="FAQ-questions">

                <div>
                    <input type="checkbox" id="question14" name="q" className="questions" />
                    <div className="plus">+</div>
                    <label htmlFor="question14" className="question">
                        איך הולך תהליך הסרת השיער בלייזר?
                    </label>
                    <div className="answers">
                        <p>בדיקת ההתאמה היא השלב הכרחי ביותר בביצוע הטיפול. מומלץ לאשר שגוון העור וצבע השיער של המטופל/ת מתאימים לטיפול המבוקש לפני התחלת התהליך. במקרים מסוימים, יתבצע גם בדיקה נוספת כדי לאמת את רמת הכאב שהמטופל/ת יכול/ה לעמוד בו מהטיפול בלייזר. המידע האישי, הפיזיולוגי והנתונים הצילומיים יתווספו לתיק האישי הממוחשב של המטופל/ת, המכיל פרטים אישיים, פרטי טיפול והתקדמות התהליך. במילוי טופס הסכמה, יוענקת ההסכמה לטיפול והוא נשמר בתיק האישי הממוחשב גם כן. אחרי השלבים הקודמים, ניתן להתחיל בטיפול עצמו של הסרת השיער באמצעות מכונת הלייזר.
                            הטיפול בהסרת שיער בלייזר מתחלק לשלושה שלבים עיקריים: הכנה לפני הטיפול, ביצוע הטיפול וסיום הטיפול עם הנחיות לאחר הטיפול הבא.
                            בשלב ההכנה לפני הטיפול, יש לגלח את האזור שיעוד לטיפול בלייזר כ-24 שעות לפני הטיפול. יש לרטוט את האזור במים פושרים, להוסיף סבון או קצף גילוח ולגלח בקפידה. גילוח יסודי ומטופל מסייע למנוע עור מגורה או רגיש. בנוסף, ניתן להשתמש במשחת אלחוש או מכשיר הורדת שיער בטמפרטורה קרה על מנת להקל על התחושות הללו.
                            בשלב הטיפול עצמו, נעביר את ידית הלייזר על שטח העור באזור המטופל. אורכו של הטיפול יתקבע על פי גודל האזור ומיקומו. לדוגמה, טיפול באזור השפה העליונה עשוי לקחת מספר דקות, בעוד טיפול ברגליים עשוי לקחת כ-40 דקות.
                            לסיום הטיפול, יש לנקות את האזור משאריות הג'ל ולמרוח משחה מרגיעה על העור. יש גם לקבל הוראות בתיק הממוחשב שכוללות הנחיות לטיפוח העור בשבועות שיחלפו עד הטיפול הבא. כמה כלל</p>
                    </div>
                </div>


                <div>
                    <input type="checkbox" id="question15" name="q" className="questions" />
                    <div className="plus">+</div>
                    <label htmlFor="question15" className="question">
                        למי אסור לעבור הסרת שיער בלייזר?
                    </label>
                    <div className="answers">
                        <p>מי שנוטל רואקוטן (תרופה נגד אקנה), או תרופות על בסיס זהב (למשל, נגד דלקת מפרקים), וחולי ויטליגו, שאצלם עלולים להיווצר כתמים כהים וכוויות באזור הטיפול.
                            <br />• תקופת ההיריון – לאורך 9 חודשי ההיריון, לא ניתן יהיה לבצע תהליך של הסרת שיער בלייזר.
                            בזמן הנקה ישנו איסור מוחלט לטפל סביב הפטמות בלבד וניתן לחזור לטיפולים לאחר 3 חודשים מהלידה.
                            ◦ שיער בהיר – במידה וגוון השערה שלכם בהיר, סביר כי הסרת השיער בשיטת הלייזר לא תתאים לכם. על כל פנים, בכדי לספק לכם חוות דעת מקצועית ואחראית, אנו מזמינים אתכם לתאם איתנו פגישת ייעוץ.</p>

                    </div>
                </div>

                <div>
                    <input type="checkbox" id="question12" name="q" className="questions" />
                    <div className="plus">+</div>
                    <label htmlFor="question12" className="question">
                        מי הרופא שעושה הזרקות אצלכן בקליניקה?
                    </label>
                    <div className="answers">
                        <p><strong>דר. יוסף אסאדי</strong> סיים רפואה כללית בשנת 2018 במולדבה, רופא מרדים בכיר בבית חולים נהריה מעל 4 שנים והתחיל את דרכו בעולם האסתטיקה בשנת 2019 במרפאות וקליניקות שונות בחיפה עד שפתח את המרפאה שלו בשנת 2020
                            בסוכר קליניק דואגים לרמת עבודה מקצועית עם החומרים והמזרקים הטובים ביותר בשוק
                            רמה גבוהה של עבודה יכולה לשמור על מראה טבעי ומשופר מותאם בשבילך לתווך הארוך במידה האפשרית
                            לפני תחילת הטיפול הינך מחוייבת לעבור שיחת ייעוץ בקליניקה אשר יוסבר לך על תהליך הטיפול/ים ומה צריך בשביל להגיע לתוצאה המבוקשת, הרופא והייועצת יתאמו איתך ציפיות עם הסברים מופרטים בנוגע לטיפול
                            לשמוע להנחיות והוראות הרופא הינו קריטי לתוצאת ובטיחות הטיפול
                            הצוות ילווה אותך לפני ואחרי הטיפול בשביל לדאוג שהינך מרוצה ומסופקת</p>
                    </div>
                </div>

                <div>
                    <input type="checkbox" id="question13" name="q" className="questions" />
                    <div className="plus">+</div>
                    <label htmlFor="question13" className="question">
                    מה זה בוטוקס? ומה זה חומצה היאלורונית?
                    </label>
                    <div className="answers">
                        <p><strong>בוטוקס</strong> הוא שם מסחרי של תרופה המכילה חומר פעיל בשם "בוטולינומי טוקסין איחוד טיפוח"  עיקר השימושים הקוסמטיים של בוטוקס הם להפחתת קמטים וקמטוטים בעור הפנים.
הבוטוקס פועל על ידי חוסמו של השרירים המתחתים לעור, ובכך מפחית את התמונה של הקמטים. החומר הפעיל בבוטוקס משתלב עם חלק ממתכות החשמל בשריר, ובכך מונע מהשריר להתכווץ , ובתור כך מפחית את ההברגשות הקמטיות בעור. הטיפול בבוטוקס הוא יעיל ויציב, אך השפעתו זמנית וצריכה להיחדש בקביעות.
לעומת זאת, חומצה היאלורונית היא חומר טבעי הנמצא בגוף האדם, בעיקר בעור וברקמות החיבור. חומצה היאלורונית מהווה חלק בסיסי מבניית העור ותורמת לרכות, למיצוק וללחות העור. בתרפיה קוסמטית, חומצה היאלורונית משמשת כמילוי ופיסול איזורים שונים בפנים, חידוש מראה ומיצוק העור. היא מצויה במגוון מוצרים קוסמטיים</p>
                    </div>
                </div>

                <div>
                    <input type="checkbox" id="question11" name="q" className="questions" />
                    <div className="plus">+</div>
                    <label htmlFor="question11" className="question">
                    איזה איזורים עוברים בוטוקס ואיזה איזורים עוברים פילר? 
                    </label>
                    <div className="answers">
<p>אזורי בוטוקס:<br/>טיפול החלקת קמטים בהזרקת בוטוקס נעשה בעיקר במצח, בין העיניים, בצידי העיניים, סביב השפתיים ובצוואר. <br/><br/>אזורי פילר:<br/>הקמטים שבין האף לשפה העליונה והלחיים (Nsolabial fold), הקמטים בצידי השפה התחתונה, הסנטר והלחיים (marionette lines), הקמטים במצח, סביב ומתחת לעיניים. כאמור ניתן להשתמש בחומצה להדגשת עצמות הלחיים ותיקוני אף. <br/> בשונה מבוטוקס שמשתק שרירים וגורם לקמטי הבעה (במצח, בין הגבות, בצידי העיניים וכדומה), חומצה היאלורונית לא מרפה שרירים, אלא עובדת על מילוי נפחים. כמו כן, ניתן להזריק חומצה היאלורונית לצלקות שקועות, לשקע בסנטר, לרקות וגם למילוי נפחים בגוף (הגדלת חזה / ישבן וכדומה).</p>
                    </div>
                </div>
            </div>

        </div>

    )
}

export default FAQ