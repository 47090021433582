import {FaHome ,FaBriefcaseMedical,FaInstagram, FaMoneyCheckAlt, FaFileMedical, FaBuilding, FaBusinessTime, FaThList} from "react-icons/fa"
import {AiOutlinePieChart,AiOutlineFileDone,AiFillInstagram, AiOutlineClockCircle, AiOutlineCalculator} from "react-icons/ai"
import {MdImportantDevices, MdOutlineMiscellaneousServices, MdComputer, MdContactPhone, MdPrivacyTip} from "react-icons/md"
import {RiWhatsappFill, RiCapsuleFill} from "react-icons/ri"
import {HiLightBulb,HiShoppingBag} from "react-icons/hi"
import {BsCalculatorFill, BsFillFileEarmarkTextFill, BsFillFileSpreadsheetFill,BsFillTelephoneFill, BsUiChecksGrid, BsFillPatchCheckFill} from "react-icons/bs"
import {GrMail} from "react-icons/gr"
import {GoChecklist} from "react-icons/go"
import {GiLoveInjection, GiMedicalThermometer, GiFemaleLegs} from "react-icons/gi"


export const getIcon = (tool,size=45) => {
  switch (tool) {
    case 'legs':
      return <GiFemaleLegs size={size}/>
    case 'calc':
      return <BsCalculatorFill size={size}/>
    case 'calc2':
      return <AiOutlineCalculator size={size}/>
    case 'invoice':
      return <BsFillFileEarmarkTextFill size={size}/>
    case 'filespreadsheet':
      return <BsFillFileSpreadsheetFill size={size}/>
    case 'building':
      return <FaBuilding size={size}/>
      
    case 'checkbuilding':
      return <BsUiChecksGrid size={size}/>

    case 'checkmark':
      return <BsFillPatchCheckFill size={size}/>

    case 'checklist':
      return <GoChecklist size={size}/>

    case 'time':
      return <FaBusinessTime size={size}/>

    case 'homeservice':
      return <FaHome size={size}/>
  
    case 'shield':
      return <MdPrivacyTip size={size}/>
  
    case 'injection':
      return <GiLoveInjection size={size}/>
  
    case 'medicalfile':
      return <FaFileMedical size={size}/>
  
    case 'brief':
      return <FaBriefcaseMedical size={size}/>
      
    case 'about':
      return <FaBriefcaseMedical size={size}/>
  
    case 'thermometer':
      return <GiMedicalThermometer size={size}/>
  
    case 'capsules':
      return <RiCapsuleFill size={size}/>
  
    case 'design':
      return <MdImportantDevices size={size}/>
  
    case 'media':
      return <FaInstagram size={size}/>
  
    case 'marketing':
      return <AiOutlinePieChart size={size}/>
  
    case 'solutions':
      return <AiOutlineFileDone size={size}/>
  
    case 'clock':
      return <AiOutlineClockCircle size={size}/>

    case 'idea':
      return <HiLightBulb size={size}/>

    case 'online':
      return <HiShoppingBag size={size}/>

    case 'IG':
      return <AiFillInstagram size={size}/>

    case 'WSP':
      return <RiWhatsappFill size={size}/>

    case 'PHONE':
      return <BsFillTelephoneFill size={28}/>

    case 'MAIL':
      return <GrMail size={size}/>

      // Nav icons
    case 'home':
      return <FaHome size={size}/>

    case 'services':
      return <MdOutlineMiscellaneousServices size={size}/>
    case 'tipolim':
      return <FaThList size={size}/>

    case 'pricing':
      return <FaMoneyCheckAlt size={size}/>

    case 'ourwork':
      return <MdComputer size={size}/>

    case 'contact':
      return <MdContactPhone size={size}/>


  
    default:
      break;
  }
}