import "./WhatsappBubble.css"
import { getIcon } from '../../utils/iconGetter'
import { wspLink } from '../../utils/WspLink'

const WhatsappBubble = () => {

    return (

        <div className='WhatsappBubble'>
            <a href={wspLink}>{getIcon('WSP',32)}</a>
        </div>

    )
}

export default WhatsappBubble