import './JustImage.css'


const JustImage = ({ bg, ht }) => {

    return (

        <div className='JustImage flex-center section'>
            <div className="just-image" style={{ backgroundImage: `url(${bg})`, height:ht}}></div>
        </div>

    )
}

export default JustImage