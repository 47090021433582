import './TipolimCard.css'
import { useContext, useState } from 'react'
import LanguageContext from '../../contexts/LanguageContext'
import ThemeContext from '../../contexts/ThemeContext'

const TipolimCard = ({ title, desc }) => {

    const { language } = useContext(LanguageContext)
    const { theme } = useContext(ThemeContext);
    const [isHover, setIsHover] = useState(false);
    const [isClicked, setIsClicked] = useState(false);

    const handleMouseEnter = () => {
        setIsHover(true);
    };
    
    const handleMouseLeave = () => {
        setIsHover(false);
    };

    const handleClick = () => {
        setIsClicked(!isClicked);
    };

    return (
        <div
            className={`TipolimCard ${isClicked ? 'clicked' : ''}`}
            style={{
                backgroundColor: isHover ? (theme.mode === "dark" ? "black" : theme.primary) : theme.cardsBg, 
                color: isHover ? (theme.mode === "dark" ? "vanil" : theme.cardsBg) : theme.primary 
            }}
            onMouseLeave={handleMouseLeave}
            onMouseEnter={handleMouseEnter}
            onClick={handleClick}>
            <h2 className={`tc-${language}`}>{title}</h2>
            {isClicked && <p style={{textAlign:'right'}} className={`tc-${language}`}>{desc}</p>}
        </div>
    )
}

export default TipolimCard
