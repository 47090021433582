import './App.css';
import { useContext } from 'react';
import LanguageContext from './contexts/LanguageContext'
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import AOS from 'aos';
import 'aos/dist/aos.css';
import heLang from './languages/hebrew.json'
import Home from './pages/Home/Home';
import ThemeContext from './contexts/ThemeContext';



function App() {

  AOS.init({
    offset: 200,
  });

  const { language } = useContext(LanguageContext);
  const { theme } = useContext(ThemeContext);
  document.body.style.backgroundColor = theme.bodyBg

  return (

    <div className={`App ${language}`} style={{color:theme.primary}}>
      <Router>
        <Routes>
        <Route index element={<Home lng={heLang} font={'he'} />} />
        {/* <Route path={`/ar`} element={<Home lng={arLang} font={'ar'} />} /> */}
        </Routes>
      </Router>
    </div>
  );
}

export default App;
