import {createContext,useState} from 'react';

const ThemeContext = createContext();
export const ThemeProvider = ({children}) => {
  const [theme,setTheme] = useState({
          mode:"light",
          bodyBg:"#F5F0E6",
          cardsBg:"#DD5154",
          cardsBgHover:"#B9373A",
          primary:"#454A4D",
          secondary:"#F5F0E6",
        });


  const changeTheme = () => {
    if(theme.mode === 'light'){
        setTheme({
          mode:"dark",
          bodyBg:"#323233",
          cardsBg:"#1E1E1E",
          cardsBgHover:"#171717",
          primary:"#34B173",
          secondary:"#28AAE0",
        })
    }else{
        setTheme({
          mode:"light",
          bodyBg:"#F6F7F7",
          cardsBg:"#fff",
          cardsBgHover:"#2F3192",
          primary:"#2F3192",
          secondary:"#2F3192",
          logo:"qbmedia-slogan.webp"
        })
    }
  }
  

  return <ThemeContext.Provider value={{
    theme,
    setTheme,
    changeTheme,
    }}>
{children}
    </ThemeContext.Provider>
}

export default ThemeContext